import React from "react";

export default function Tulare() {
    return(
        <div className="city-dropdown">
            <button className="city-dropbtn">Tulare</button>
            <div className="city-dropdown-content">
                <a href="https://www.tulare.ca.gov/home/showpublisheddocument/22406/638666520462230000" target={'_blank'} rel='noreferrer'>Benchmarks</a>
            </div>
        </div>
    )
};
