import React from 'react'

export default function Auborn() {
    return (
        <div className='city-dropdown'>
            <button className='city-dropbtn'>Auborn</button>
            <div className='city-dropdown-content'>
                <a href='https://gis.auburnwa.gov/surveymonumentsviewer/Prod/index.html' target={'_blank'} rel='noreferrer'>Benchmark Monumnet GIS</a>
            </div>
        </div>
    )
}

