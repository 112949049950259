import React from 'react'

export default function PismoBeach(){
    return (
        <div className='city-dropdown'>
            <button className='city-dropbtn'>Pismo Beach</button>
            <div className='city-dropdown-content'>
                <a href='https://accesspismo.pismobeach.org/WebLink/DocView.aspx?id=250037&dbid=0&repo=Laserfiche&cr=1' target={'_blank'} rel='noreferrer'>Benchmarks</a>
            </div>
        </div>
    )
}

