import React from 'react'

export default function Reedly() {
    return (
        <div className='city-dropdown'>
            <button className='city-dropbtn'>Reedly</button>
            <div className='city-dropdown-content'>
                <a href='https://reedley.ca.gov/engineering-department/technical-library/' target={'_blank'} rel='noreferrer'>Benchmarks, Ties, Utilities</a>                
            </div>
        </div>
    )
}

